<template>
  <div>
    <core-toolbar>QUEM SOMOS</core-toolbar>
    <v-container>
      <v-row justify="center">
        <v-col md="6">
          <blockquote class="text-justify">
            A nossa empresa, localizada na cidade de Lajeado está presente no
            mercado funerário desde 1925 , renova-se constantemente e enriquece
            o profissionalismo adquirido ao longo dos anos e gerações através da
            atualização dos seus serviços e ferramentas. Todos os recursos da
            empresa são utilizados para oferecer o mais alto nível de qualidade
            em relação às necessidades e possibilidades específicas dos
            familiares. A filosofia da actividade assenta na confidencialidade ,
            na qualidade e eficiência dos serviços e no profissionalismo . A
            equipe está empenhada em ouvir até os pedidos mais exigentes dos
            clientes, tranquilizando-os com sensibilidade e empatia, sem sinais
            de indiferença ou desinteresse.
          </blockquote>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col>
          <v-row>
            <v-col md="3" sm="6" v-for="image in images" :key="image">
              <v-img :src="image" alt="">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    />
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {
    CoreToolbar: () => import('@/components/site/core/CoreToolbar'),
  },

  data: () => ({
    images: [
      'images/quem_somos_1.jpg',
      'images/quem_somos_2.jpg',
      'images/quem_somos_3.jpg',
      'images/quem_somos_4.jpg',
    ],
  }),
}
</script>
